import React from "react";
import KurveContainer from "../../../GjenbruksElement/Kurver/KurveContainer";
import Navigeringsliste from "./Navigeringsliste/Navigeringsliste";
import Kurve from "../../../GjenbruksElement/Kurver/Kurve";

const KatalogBarneliste = ({
  data,
  meta,
  onNavigate,
  onUpdateMetaProp,
  opplyst,
  onMouseEnter,
  onMouseLeave
}) => {
  /*

Navigation

  */
  const { kode } = meta;
  if (meta.barn.length === 0) return null;
  //TEMPORARILY SHUTTING OFF SUBELEMENTS
  // AS THEY MAKE LESS SENSE IN THIS FORMAT
  return (
    <>
      {false && (
        <>
          <h1>Underelementer</h1>
          <Navigeringsliste
            title=""
            parentkode={kode}
            størsteAreal={data && data.størsteAreal}
            apidata={data && data.barn}
            metadata={meta.barn}
            onNavigate={onNavigate}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            opplyst={opplyst}
            onUpdateMetaProp={onUpdateMetaProp}
            isDatakilde={meta.tittel.nb}
          />
        </>
      )}

      {false && (
        <>
          <KurveContainer
            key={"a.url"}
            punkt={{
              url:
                "Biota/Plantae/Magnoliophyta/Eudicots/Ericales/Primulaceae/Primula/Scandinavica"
            }}
            gradient={{
              url:
                "Natur_i_Norge/Landskap/Landskapsgradient/Arealbruksintensitet/",
              barn: []
            }}
          >
            <Kurve logY={true} />
          </KurveContainer>
        </>
      )}
    </>
  );
};

export default KatalogBarneliste;
