const lights = {
  sol: {
    type: "ambient",
    ambient: 1
  }
};

function createLights() {
  return lights;
}

export { createLights };
