import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

const Naturvern = () => {
  return (
    <SvgIcon style={{ fill: "hsla(0, 0%, 0%, 0.54)" }}>
      <path d="m 2.8461255,12.311837 v 9.943137 l 1.1043174,-0.440871 c 0.8260979,-0.333863 3.2658689,-1.181363 3.937876,-1.369696 0.038523,-0.01284 0.051364,-1.331173 0.051364,-6.416169 V 7.629189 h 3.9378751 3.937877 v 5.671398 c 0,3.120338 0.0086,5.671397 0.01284,5.671397 0.0086,0 0.556439,-0.07704 1.211325,-0.171212 1.309772,-0.188333 3.004771,-0.402348 3.56121,-0.449431 l 0.350984,-0.02996 V 10.342899 2.36442 H 11.89896 2.8461255 Z" />
    </SvgIcon>
  );
};
export default Naturvern;
