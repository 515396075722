const showNinCodes = "visKoder";
const showNinCodesText = "Vis NiN-koder";
const interval = "Intervall";

const constants = {
  interval,
  showNinCodes,
  showNinCodesText
};

export default constants;
